import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'

const PageContact = props => (
    <Layout {...props}>
        <Seo title='Contact' />
        <Divider />
        <Stack>
            <Main>
                <PageTitle
                    header="Let's get in touch"
                    subheader='You are about to take the first step towards something great.'
                />
                <Divider />
                <ContactForm />
            </Main>
        </Stack>
    </Layout>
)

export default PageContact
